import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Guard que verifica si el usuario tiene una sesión iniciada. 
 * * Depende de UserService
 * 
 * INSTRUCCIONES DE USO: Utilizar en el canActivate de las routes
 */
export class AuthGuard implements CanActivate {

  constructor(private userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.userService.getCurrentUser(state.url)
  }
 
}
