import {Pipe, PipeTransform} from '@angular/core'
import { CryptoSharkService } from '../services/crypto-shark.service';
import { UserService } from '../services/user.service';

@Pipe({
    name: 'decrypt'
})

/**
 * Pipe para desencriptar datos.
 * * Depende de CryptoSharkService
 * 
 * INSTRUCCIONES DE USO: 
 * 1) Importar PipesModule en el módulo donde quieras desencriptar
 * 2) Utilizar el pipe 'decrypt' en el html de tu componente
 */

export class DecryptPipe implements PipeTransform {

    constructor(private cryptoService: CryptoSharkService, private userService: UserService) {}

    transform(value: string): string {
        return value != null ? this.cryptoService.decrypt(value, this.userService.login.company.dk) : ''
    }
}