import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  constructor(private socket: Socket) { }

  public joinRoom = (domain: string): any => {
    this.socket.emit('joinRoom', {room: domain});
    // console.log('emitiando');
  };

  public joinRooms = (domain: string): any => {
    this.socket.emit('rata', 'rata');
    // console.log('emitiando');
  };

  listenData() {
    return this.socket.fromEvent('data');
  }

   disconnect(): any {
     return this.socket.disconnect();
   }

   connect(): any {
    return this.socket.connect();
  }
}