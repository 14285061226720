import { Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: '[ValidateForms]'
})
export class ValidateFormsDirective {

  constructor() {}

  campoEsValido( field: string, formRules: FormGroup ): boolean {
    return formRules.controls[ field ].errors && formRules.controls[ field ].touched;
  }

  validField = (field: string, formRules: FormGroup): boolean => {
    return formRules.controls[ field ].errors && formRules.controls[ field ].touched;
  }

}
