import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../services/user.service';

@Pipe({
  name: 'imagesLoad'
})

export class ImagesLoadPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer, private userService: UserService){}

  transform(img: any): any {      
    // let domImg = (img.name ? this.userService.login.company.server+img.name: 'assets/default.jpeg');
    let domImg = (img ? img: 'assets/default.jpeg');  
    return this.domSanitizer.bypassSecurityTrustResourceUrl(domImg);
  }

}
