<h6>Semana Calendario <span class="text-danger">*</span></h6>
<div class="input-group date">
    <div class="input-group-prepend">
        <div class="input-group-text date-week"><b>SEM {{ selectedDate.week || '...' }}</b></div>
    </div>
    <input class="form-control text-center border-left-0" 
        ngxDaterangepickerMd opens="left" 
        placeholder="DD/MM/YYYY"
        readonly
        [disabled]='options.type == "EDIT"'
        [(ngModel)]="selectedDate"
        [ngModelOptions]="{standalone: true}" 
        (change)="selectDate($event)"
        [locale]="locale"
        [autoApply]="true" 
        [singleDatePicker]="true"
        [showWeekNumbers]="true"  
        [maxDate]="maxDate" 
        [showCustomRangeLabel]="true">
</div>