<div class="main-body main-sidebar-hide leftmenu" [ngClass]="mainSidebarOpen">
    <!-- main-sidebar-hide -->
    <div class="page">

        <!-- Sidemenu -->
        <app-sidemenu (mouseover)="hoverEffect($event)" (mouseout)="hoverEffect($event)" [isOpen]="mainSidebarOpen.length"></app-sidemenu>
        <!-- <app-sidemenu></app-sidemenu> -->
        <!-- /Sidemenu -->

        <!-- Header -->
        <app-header></app-header>
        <!-- /Header --> 
         
        <!-- Ventana modal para bloquear al usuario--> 
        <app-block-modal></app-block-modal>

        <!-- Main Content -->
        <div class="main-content side-content pt-0">
            <div class="container-fluid">
                <div class="inner-body" (click)="clickonBody()"> 
                    <router-outlet (activate)="hoverEffect($event,false)"></router-outlet>
                </div>
            </div>
        </div>
        
        <!-- /Main Content -->

        <!-- Footer -->
        <app-footer></app-footer>
        <!-- /Footer -->

        <!-- Notification-sidebar -->
        <app-notification-sidebar></app-notification-sidebar>
        <!-- /Notification-sidebar -->

    </div>

</div>