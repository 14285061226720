<ng-container *ngIf="alerts.length"><div class="card">
    <div class="scroll scroll-{{index}}">
        <ng-container swiperSlide *ngFor="let alert of alerts; index as i">
            <alert-content [alert]="alert" (onCLose)="close(i)"></alert-content>
        </ng-container>
    </div>
</div>
<div class="hideBtn" (click)="show = !show;">
    <i class="fa fa-chevron-down"></i>
</div>
</ng-container>