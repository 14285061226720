import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalAlertService } from '../../services/global-alert.service';
import { HttpService } from '../../services/http.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss']
})
export class FullLayoutComponent implements OnInit {
  
  @ViewChild('content', {static: false}) contenidoDelModal;
 
  constructor(
    private modalService: NgbModal,
    private globalAlert: GlobalAlertService,
    private httpService: HttpService,
    private userService: UserService
  ) {}

  public mainSidebarOpen: any;

  async ngOnInit() { 

    let loginData = this.userService.login;
    let data = await this.httpService.getMessages(loginData?.subdomain).toPromise();

    data.forEach(message => {
      this.globalAlert.openAlert(message);
    });

  }

  hoverEffect($event, keepOpen = true) {
    if( $event?.type == 'mouseover' && keepOpen){
      this.mainSidebarOpen = 'main-sidebar-open';
      document.querySelector('body').classList.add('overflow-hidden')
    } else if($event?.x > 70 || !keepOpen){
      this.mainSidebarOpen = '';
      document.querySelector('body').classList.remove('overflow-hidden')
    }
  }
  
  clickonBody(){
    this.mainSidebarOpen = '';
    document.querySelector('body').classList.remove('overflow-hidden')
  }

  open() { 
    this.modalService.open(this.contenidoDelModal, {backdrop: 'static', keyboard: false}); 
  }

}