<mk-loader *ngIf="isLoading" class="loader-spinner"></mk-loader>
<div class="modal-body"> 
    <div class="row border-bottom pb-3 mb-3">
        <div class="col-12">
          <h3 class="text-primary m-0"><i class="fa fa-lock op-5"></i> Cambio de credenciales</h3>
          <span class="text-muted">Registra una nueva <b>contraseña</b> y <b>NIP</b></span>
        </div>
      </div>
    <form [formGroup]="form" novalidate>
        <div class="row">
            <div class="col-6">
                <h6>Contraseña <span class="text-danger">*</span></h6>
                <input type="password" class="form-control" placeholder="**********" formControlName="newPass" maxlength="16">  
                <small *ngIf="validatorForm.campoEsValido('newPass',form)" class="text-danger">Campo requerido, Máximo 16 caracteres</small>
            </div>
            <div class="col-6">
                <h6>Confirmar contraseña <span class="text-danger">*</span></h6>
                <input type="password" class="form-control" placeholder="**********" formControlName="confirmNewPass" maxlength="16">  
                <small *ngIf="form?.controls?.confirmNewPass?.errors?.mustMatch" class="text-danger">Contraseña no coincide !<br></small>
                <small *ngIf="validatorForm.validField('confirmNewPass',form)" class="text-danger">Campo requerido, Máximo 16 caracteres</small>
            </div>
        </div>
        <div class="row border-top pt-3 mt-3">
            <div class="col-6">
                <h6>NIP <span class="text-danger">*</span></h6>
                <input type="password" pattern="[0-9]*" inputmode="numeric" class="form-control" (keypress)="validateInput.isInteger($event)" placeholder="****" formControlName="newNip" maxlength="4">  
                <small *ngIf="validatorForm.campoEsValido('newNip',form)" class="text-danger">Campo requerido, 4 dígitos</small>
            </div>
            <div class="col-6">
                <h6>Confirmar NIP <span class="text-danger">*</span></h6>
                <input type="password" pattern="[0-9]*" inputmode="numeric" class="form-control" (keypress)="validateInput.isInteger($event)" placeholder="****" formControlName="confirmNewNip" maxlength="4">  
                <small *ngIf="form?.controls?.confirmNewNip?.errors?.mustMatch" class="text-danger">Nip no coincide !<br></small>
                <small *ngIf="validatorForm.campoEsValido('confirmNewNip',form)" class="text-danger">Campo requerido, 4 dígitos</small>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-danger" (click)="close()">Cancelar</button>
    <button type="button" class="btn btn-success" (click)="submit()"><i class="fa fa-check mr-2"></i> Guardar</button>
</div>