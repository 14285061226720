import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ILogin } from '../../../app/interfaces/ilogin'//'src/app/interfaces/ilogin';
import { datePicker } from 'src/app/shared/mk-configs/datePicker/datePickerLocale';
import { ISelectedDay } from 'src/app/interfaces/checker/ISelectedDay';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class LanguageService {

  private _json_lang: any = {};
  private _default_lang: string = '';
  private _selected_lang: any = {};
  private _languages_list: any[] = [];
  private _multiLanguageWeb: boolean = false;
  
  constructor(private http: HttpService) {
    moment.locale(this.default_lang == "MX" ? 'ES' : this.default_lang == "US" ? 'EN' : 'ES'); 
  }

  public getLanguage = (fileName: string): Observable<any> => this.http.getEvaMk5(`api/company/lang/${fileName}`);
  //public get = (url: string, contentType?: string): Observable<any> => this.http.get(url, contentType);

  /**
   * Metodo para traer toda la configuracion de los idiomas al sitio
   * define el idioma nativo segun lo declarado por eva
   */
  public async getSettingsLanguage(): Promise<number> {
    try{
      this.getSubdomain();
      return new Promise((resolve) => {
        this.http.getEvaMk5(`api/login/company/${environment.subdomain}`).subscribe((data) => {
          this._default_lang = data.data?.defaultLanguage ?? 'MX';
          this._json_lang = data.data?.jsonLanguage ?? {};
          this._languages_list = data.data?.languages ?? [];
          this._multiLanguageWeb = data.data?.multiLanguageWeb ?? false;
          this._selected_lang = this._languages_list.filter(x => x.name == this._default_lang) ?? {};
          moment.locale(this._default_lang == "MX" ? 'ES' : this._default_lang == "US" ? 'EN' : 'ES');           
          resolve(0)
        }),(reject) => {
          reject(1);
        }   
      });
    }
    catch(error){
      console.error(error);
    }
  }

  /**
   * Configura nuevo idioma en el servicio de mikiosko
   * en caso de usar variable locales volveras a declarar despues de usar este metodo
   */
  public async setSettingsLanguage(selectedLang: any, change4user: boolean = true): Promise<number>{
    try{
      this._selected_lang = selectedLang;
      this._default_lang = selectedLang.name;
      return new Promise((resolve) => {
        this.getLanguage(this._selected_lang.file).subscribe((data) => {
          this._json_lang = data;

          /* Set offline */
          const login: ILogin = JSON.parse(localStorage.getItem('ldt'));

          if(login){
            login.company.jsonLanguage = this._json_lang;
            login.company.defaultLanguage = selectedLang.name;

            localStorage.setItem('ldt', JSON.stringify(login));
          }
          moment.locale(this._default_lang == "MX" ? 'ES' : this._default_lang == "US" ? 'EN' : 'ES'); 

          /* START Settings de Usuario */
          if(change4user){
            this.setSettingsLanguageUser();
          }
          /* END Settings de Usuario */

          resolve(0);
        }),(reject) => {
         reject(1); 
        }
      })
    }
    catch(error){
      console.error(error)
    }
  }

  /**
   * Configura nuevo idioma en el servicio de mikiosko
   * por usuario
   */
  private async setSettingsLanguageUser(){
    let userData = {"language":this.default_lang}
    let response = await this.http.post('api/changeLanguage',userData).subscribe(response => console.log(response.msg));
    return response;
  }

   /**
   * Configura idioma en el servicio de traduccion de mikiosko
   * por usuario
   */
  public async setLanguageForUser(languageName: string){
    if(languageName){
      let languageSelected = this.languages_list.filter(x => x.name == languageName)[0] ?? null;
      if(languageSelected){
        this.setSettingsLanguage(languageSelected,false);
      }
    }
  }

  /**
   * Configura nuevo idioma en el servicio de mikiosko
   * en caso de usar variable locales volveras a declarar despues de usar este metodo
   */
  public getNameModule(module: string): string{
    try{
      
      let translateModule = this.json_modules ? this.json_modules[module] : null;
      return translateModule;
    }
    catch(error){
      console.error(error);
      return null;
    }
  }

  /**
   * Configura el mensaje de respuesta del back
   */
  public getResponseBack(response: string): string{
    try{
      let translateBack = this.json_back ?? null;
      if(translateBack){
        /*
        var translateResponse = response.split('.').reduce(function(o,ki) {
          return o && o[ki];
        },translateBack) ?? null; */
        let translateResponse = response.split('.').reduce((a,b) => {return a && a[b]},translateBack) ?? null;
        return translateResponse;
      }
      else{
        return null;
      }
    }
    catch(error){
      console.error(error);
      return null;
    }
  }

  /**
   * Obtener datos del subdominio
   */
  getSubdomain() {
    const domain = window.location.hostname;
    if (
      domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'example' ||
      domain.split('.')[0] === 'lvh' ||
      domain.split('.')[0] === 'www' ||
      domain.split('.')[0] === '45'
    ) {
      environment.subdomain = 'dani';
    } else {
      environment.subdomain = domain.split('.')[0];
    }
  }
  
  /**
   * Obtiene datos del dateOptions
   */
  get datePicker() {
    if(!this.default_lang){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._default_lang = login?.company?.defaultLanguage ?? '';
    }
    return this.default_lang == "MX" ? datePicker.locale.spanish : this.default_lang == "US" ? datePicker?.locale?.english : datePicker?.locale?.spanish;
  }

  /**
   * Obtiene dias para graficar
   */
  get initialDays(){
    let _initialDays: ISelectedDay[];

    if(!this.default_lang){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._default_lang = login?.company?.defaultLanguage ?? '';
    }

    if(this.default_lang == 'US'){
      _initialDays = [
        { name: 'Mo', selected: false, value: 1 },
        { name: 'Tu', selected: false, value: 2 },
        { name: 'We', selected: false, value: 3 },
        { name: 'Th', selected: false, value: 4 },
        { name: 'Fr', selected: false, value: 5 },
        { name: 'Sa', selected: false, value: 6 },
        { name: 'Su', selected: false, value: 7 }
      ];
    }
    else{
      _initialDays = [
        { name: 'Lu', selected: false, value: 1 },
        { name: 'Ma', selected: false, value: 2 },
        { name: 'Mi', selected: false, value: 3 },
        { name: 'Ju', selected: false, value: 4 },
        { name: 'Vi', selected: false, value: 5 },
        { name: 'Sa', selected: false, value: 6 },
        { name: 'Do', selected: false, value: 7 }
      ];
    }

    return _initialDays;
  }

  /**
   * Obtiene datos del JSON de traduccion
   */
  get json_lang() {
    if(!this._json_lang){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang;
  }

   /**
   * Obtiene el nombre de la traduccion actual
   */
   get default_lang() {
    if(!this._default_lang){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._default_lang = login?.company?.defaultLanguage ?? 'MX';
    }
    return this._default_lang;
  }

  /**
   * Obtiene un arreglo de los idiomas de traduccion
   */
  get languages_list() {
    if(this._languages_list.length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._languages_list = login?.company?.languages ?? [];
    }
    return this._languages_list;
  }

  /**
   * Obtiene el booleano del multilenguaje
   */
  get multiLanguageWeb() {
    return this._multiLanguageWeb;
  }

   /**
   * Obtiene el objeto de la traduccion 
   */
   get selected_lang() {
    if(Object.keys(this._selected_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._selected_lang = login?.company?.languages.filter(x => x.name == login?.company?.defaultLanguage)[0];
    }
    return this._selected_lang;
  }

  /**
   * Obtiene datos del JSON de traduccion para el modulo de checador Checker
   */
  get json_checker() {
    if(Object.keys(this._json_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang.web?.default?.pages?.checker;
  }

  /**
   * Obtiene datos del JSON de traduccion para el componente de checador History Column
   */
  get json_historyColumn() {
    if(Object.keys(this._json_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang.web?.default?.components?.history_column;
  }

  /**
   * Obtiene datos del JSON de traduccion para el componente de checador Assign Device
   */
  get json_assignDevice() {
    if(Object.keys(this._json_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang.web?.default?.components?.assign_device;
  }

  /**
   * Obtiene datos del JSON de traduccion para el componente de checador Assign geofence
   */
  get json_assignGeofence() {
    if(Object.keys(this._json_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang.web?.default?.components?.assign_geofence;
  }

  /**
   * Obtiene datos del JSON de traduccion para el componente de checador Assign geofence
   */
  get json_checking() {
    if(Object.keys(this._json_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang.web?.default?.components?.checking;
  }

   /**
   * Obtiene datos del JSON de traduccion para el componente de checador cheker export
   */
   get json_exportchecks() {
    if(Object.keys(this._json_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang.web?.default?.components?.export_checks;
  }

  /**
   * Obtiene datos del JSON de traduccion para el componente de checador geofence
   */
  get json_geofence() {
    if(Object.keys(this._json_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang.web?.default?.components?.geofence;
  }

  /**
   * Obtiene datos del JSON de traduccion para el componente de checador history
   */
  get json_history() {
    if(Object.keys(this._json_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang.web?.default?.components?.history;
  }

  /**
   * Obtiene datos del JSON de traduccion para el componente de checador import
   */
  get json_import() {
    if(Object.keys(this._json_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang.web?.default?.components?.import;
  }

  /**
   * Obtiene datos del JSON de traduccion para el componente de checador preview
   */
  get json_preview() {
    if(Object.keys(this._json_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang.web?.default?.components?.preview;
  }

  /**
   * Obtiene datos del JSON de traduccion para el componente de checador reports
   */
  get json_reports() {
    if(Object.keys(this._json_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang.web?.default?.components?.reports;
  }

  /**
   * Obtiene datos del JSON de traduccion para la pagina de usuario
   */
  get json_users() {
    if(Object.keys(this._json_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang.web?.default?.pages?.user;
  }

  /**
   * Obtiene datos del JSON de traduccion para el componente modal-user de Usuarios
   */
  get json_usersChanges() {
    if(Object.keys(this._json_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang.web?.default?.components?.user;
  }

  /**
   * Obtiene datos del JSON de traduccion para la pagina de empleados
   */
  get json_employees() {
    if(Object.keys(this._json_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang.web?.default?.pages?.employees;
  }

  /**
   * Obtiene datos del JSON de traduccion para el componente de empleados report
   */
  get json_report() {
    if(Object.keys(this._json_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang.web?.default?.components?.report;
  }


  /**
   * Obtiene datos del JSON de traduccion para  el componente employees de Empleados
   */
  get json_employee() {
    if(Object.keys(this._json_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang.web?.default?.components?.employee;
  }

  /**
   * Obtiene datos del JSON de traduccion para los nombres del navbar
   */
  get json_modules() {
    if(Object.keys(this._json_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang.web?.default?.modules;
  }

  /**
   * Obtiene datos del JSON de traduccion para las respuestas del back
   */
  get json_back() {
    if(Object.keys(this._json_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang.back?.response;
  }

  /**
   * Obtiene datos del JSON de traduccion para la pagina de Soporte
   */
  get json_supports() {
    if(Object.keys(this._json_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang.web?.default?.pages?.supports;
  }

  /**
   * Obtiene datos del JSON de traduccion para  el componente respuesta de Soporte
   */
  get json_respuesta() {
    if(Object.keys(this._json_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang.web?.default?.components?.respuesta;
  }

  /**
   * Obtiene datos del JSON de traduccion para  el componente soporte de Soporte
   */
  get json_support() {
    if(Object.keys(this._json_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang.web?.default?.components?.support;
  }

   /**
   * Obtiene datos del JSON de traduccion para  el componente soporte de Soporte
   */
   get json_update() {
    if(Object.keys(this._json_lang).length == 0){
      const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
      this._json_lang = login?.company?.jsonLanguage ?? {};
    }
    return this._json_lang.web?.default?.components?.update;
  }
}
