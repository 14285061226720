import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationComponent } from './authentication/authentication.component';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { HttpService } from './shared/services/http.service';
import { CryptoSharkService } from './shared/services/crypto-shark.service';
import { TokenInterceptorService } from './shared/services/token.interceptor.service';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { DataTablesModule } from "angular-datatables";
import { AlertService } from './shared/services/alert.service';
import { NgxDropzoneModule } from 'ngx-dropzone'; 
import { InterceptorMembershipService } from './shared/services/interceptor-membership.service';
import { ComponentsModule } from './components/components.module';
const config: SocketIoConfig = { url: environment.socketUrl, options: { transports: ['websocket'] } }

@NgModule({
  declarations: [
    AppComponent,
    AuthenticationComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    RouterModule,
    SharedModule,
    CarouselModule,
    HttpClientModule,
    SocketIoModule.forRoot(config),
    DataTablesModule,
    NgxDropzoneModule,
    ComponentsModule
  ],
  providers: [
    HttpService, 
    CryptoSharkService, 
    AlertService, 
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorMembershipService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}