<div class="page main-signin-wrapper">
  <!-- Row -->
  <div class="row signpages overflow-hidden">
    <div class="col-sm-12 col-lg-5 d-none d-lg-flex details px-4">
      <div class="position-relative m-auto">
        <img class="" src="../../assets/img/brand/logo-light.svg" alt="">
        <span class="d-block text-white mt-3 text-center op-8">{{translate_json?.welcome || "¡Bienvenido!"}}</span>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-lg-7 login_form ">
      <div class="card-body mt-2 mb-2">
        <div class="row">
          <div class="col-sm-12">
            <img src="../../assets/img/brand/logo.svg" class="d-lg-none header-brand-img float-left mb-4" alt="logo">
            <div class="clearfix"></div>
          </div>
        </div>
        <ng-container *ngIf="!loginMFA">
          <form [formGroup]="loginForm" (ngSubmit)="Submit()" novalidate>
            <div class="row">
              <div class="col-12">
                <h4 class="text-left mb-1">{{translate_json?.login_title || "Iniciar Sesión"}}</h4>
                <p class="mb-4 text-muted tx-13 ml-0 text-left">{{translate_json?.login_subtitle || "Ingresa al administrador de"}} 
                  <b class="text-primary">{{translate_json?.app_name || "Mi Kiosko"}} </b>.
                </p>
                <ngb-alert type="primary" [dismissible]="true" *ngIf="expiredSesionAlert">
                  <span class="alert-inner--text">{{translate_json?.expired_session || "Sesión expirada"}}</span>
                </ngb-alert>
                <ngb-alert type="danger" [dismissible]="true" *ngIf="invalidCredentials">
                  <span class="alert-inner--text">{{translate_json?.invalid_credentials || "Credenciales Invalidas"}}</span>
                </ngb-alert>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h6>{{translate_json?.user_title || "Usuario"}}</h6>
                <input class="form-control mb-3" formControlName="username" type="text" placeholder="{{translate_json?.user_placeholder || 'Ingresa Usuario'}}">
                <div *ngIf="(loginFormControl.username.touched || submitted) && (loginFormControl.username.errors?.required)" class="tx-danger">
                  <small>{{translate_json?.user_error || "Por favor, ingresa tu usuario"}}</small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-8">
                <h6>{{translate_json?.pass_title || "Contraseña"}}</h6>
                <input class="form-control mb-3 mb-lg-4" formControlName="password" type="password" placeholder="{{translate_json?.pass_placeholder || 'Ingresar Contraseña'}}">
                <div *ngIf="(loginFormControl.password.touched || submitted) && (loginFormControl.password.errors?.required)" class="tx-danger">
                  <small>{{translate_json?.pass_error || "Por favor, ingresa tu contraseña"}}</small>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <h6>{{translate_json?.nip_title || "NIP"}}</h6>
                <input class="form-control mb-4 mb-lg-3 mb-lg-4" formControlName="nip" type="password"
                  placeholder="{{translate_json?.nip_placeholder || '****'}}">
                <div *ngIf="(loginFormControl.nip.touched || submitted) && (loginFormControl.nip.errors?.required)"
                  class="tx-danger">
                  <small>{{translate_json?.nip_error || "Por favor, ingresa tu NIP"}}</small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 pr-5 d-none d-lg-flex">
                <img src="../../assets/img/brand/logoFeedbak.svg" class="img-responsive my-auto">
              </div>

              <div class="col-sm-12 col-lg-6 pl-lg-0">

                <button class="btn ripple btn-primary btn-block" type="submit"
                  [disabled]="!loginForm.valid">{{translate_json?.login_btn_label || "Ingresar"}}</button>
              </div>
              <div class="col-sm-12 d-flex d-lg-none pt-4">
                <img src="../../assets/img/brand/logoFeedbak.svg" style="width: 120px;" class="img-responsive mx-auto">
              </div>
            </div>
          </form>
        </ng-container>
        <ng-container *ngIf="loginMFA">
          <div class="row">
            <div class="col-12">
              <h4 class="text-left mb-1"><i class="fa fa-mobile text-primary mr-1"></i> {{translate_json?.two_step_title || "Verificación en dos pasos"}}</h4>
              <p class="mb-4 text-muted tx-13 ml-0 text-left">{{translate_json?.two_step_subtitle || "Por seguridad, confirma tu inicio de sesión con el codigo enviado al télefono"}} <b class="text-primary">{{translate_json.two_step_placeholder || "*** *** 1015"}}</b>.</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="_mfaCode">
                <input type="text" #_mfa{{i}} placeholder="-" maxlength="1" [(ngModel)]="mfa[i-1]"
                  (keyup)="checkMFA(i, $event)" *ngFor="let i of [1,2,3,4,5,6]">
              </div>
            </div>
            <div class="col-12">
              <span class="text-muted">{{translate_json?.two_step_error || "¿No llegó el código?"}} <a href=""><b>{{translate_json?.two_step_error_submit || "Enviar de nuevo"}}</b></a></span>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col-6 pr-5 d-none d-lg-flex">
              <img src="../../assets/img/brand/logoFeedbak.svg" class="img-responsive my-auto">
            </div>

            <div class="col-sm-12 col-lg-6 pl-lg-0">

              <button class="btn ripple btn-primary btn-block" type="submit"
                [disabled]="!(mfa.join('').length == 6)">{{translate_json?.two_step_submit || "Validar"}}</button>
            </div>
            <div class="col-sm-12 d-flex d-lg-none pt-4">
              <img src="../../assets/img/brand/logoFeedbak.svg" style="width: 120px;" class="img-responsive mx-auto">
            </div>
          </div>
        </ng-container>
      </div>
      
    </div>
  </div>
  <!-- End Row -->
  
</div>
 <!-- footer begin -->
 <app-footer>
  <div class="text-primary mb-1" *ngIf="this.languages?.length > 1">
    <ng-container *ngFor="let item of this.languages || []">
      
      <a class="mx-2" href="javascript:void(0);" (click)="changeLanguage(item)" [style.text-decoration]="selectedLanguage?.name == item?.name ? 'underline' : 'none'">({{item?.name | uppercase}}) {{item?.longname}}</a>
    </ng-container>
    <ng-container *ngIf="!this.languages?.length"><span class="text-dark op-5"></span></ng-container>
  </div>
</app-footer>
<!-- footer end -->
<ng-template #modalCredentials let-modal>
  <credentials-modal (onClose)="modal.close();" (onConfirm)="updateCredentials();"></credentials-modal>
</ng-template>