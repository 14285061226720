import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http'; 
import { tap} from 'rxjs/operators';  
import { Observable, throwError } from 'rxjs';
import { UserService } from './user.service';
import { Router } from '@angular/router';
 

@Injectable({
  providedIn: 'root'
})

export class InterceptorMembershipService implements HttpInterceptor{ 

  constructor(private userService: UserService,
              private router: Router ) { }
 
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        //console.log(event);
      },(err:any) => {
        if( err instanceof HttpErrorResponse){ 
          if( err.status == 423){ 
            this.userService.mensaje = err.error.message;
            this.router.navigate(['supports']); 
          }   
        } 
      }) 
    )
   
  }

}
