import { Component } from '@angular/core';
import data from '../../package.json'
import * as moment from 'moment'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public title = 'Mi Kiosko';
  public version = data?.version;

  constructor(){
    
  }

  async ngOnInit(){
    moment.locale('ES');
  }

}
