<div class="wrap {{alert.background}}">
    <div class="layer" *ngIf="alert.layer"><img src="../../../../assets/img/alerts/gifs/{{alert.layer}}" alt=""></div>
    <img class="icon" src="../../../../assets/img/alerts/{{alert.icon}}.png" alt="">
    <div class="text" [class.fix]="alert.button">
        <h5>{{alert.title}}</h5>
        <span [innerHTML]="alert.subtitle"></span>
    </div>
    <div class="button" *ngIf="alert.button" (click)="alert.button.event()">
        <i class="fa fa-{{alert.button.icon}}"></i>
    </div>
    <!-- <i class="fa fa-close" (click)="close(i)"></i> -->
</div>