import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { datePicker } from 'src/app/shared/mk-configs/datePicker/datePickerLocale';
import { HttpService } from 'src/app/shared/services/http.service';
import { PayrollDateRange, Options } from 'src/app/interfaces/payroll/payroll-interfaces';
import * as moment from 'moment-timezone';

@Component({
  selector: 'monthly-calendar',
  templateUrl: './monthly-calendar.component.html',
  styleUrls: ['./monthly-calendar.component.scss']
})
export class MonthlyCalendarComponent implements OnInit {

  @Output() onChangeDate: EventEmitter<PayrollDateRange> = new EventEmitter<PayrollDateRange>();

  @Input() isPrepayroll: boolean = false;
  @Input() options: Options = { id: null, type: null };

  public selectedDate: PayrollDateRange = { 
    startDate: moment().startOf('month'), 
    endDate: moment().endOf('month'),
    year: moment().year(),
    week: moment().month() + 1
  }
  public maxDate: moment.Moment = moment().add(60, 'w');
  public locale = datePicker.locale.spanish;

  constructor(
    private httpService: HttpService
  ) {}

  ngOnInit(): void {

    this.onChangeDate.emit(this.selectedDate);

    if (this.options?.type == "EDIT") {
      this.loadEditCalendar();
    }

  }

  public getMonthName = (date: moment.Moment): string => {
    let month = moment(date).locale("es").format("MMM"); 
    return month.replace(/\./g, "").toUpperCase();
  }

  public loadEditCalendar = async(): Promise<void> =>{

    try{

      let data = await this.httpService.get(`api/${this.isPrepayroll ? "prepayroll" : "payroll"}/${this.options.id}`).toPromise();
      console.log(data);
      
      this.selectedDate = { 
        startDate: moment(data.startDate), 
        endDate: moment(data.endDate),
        year: data.year,
        week: data.week
      };
      this.onChangeDate.emit(this.selectedDate);
      
    }
    catch(error){
      console.log(`Error al cargar el calendario de la ${ this.isPrepayroll ? "Pre-nomina" : "Nomina" }`, error);
    }

  }

  public selectDate = (event: PayrollDateRange): void =>{
    
    if (event.startDate) {
      this.selectedDate = { 
        startDate: event.startDate.startOf('month'), 
        endDate: event.endDate.endOf('month'),
        year: event.startDate.year(),
        week: event.startDate.month() + 1
      };
      this.onChangeDate.emit(this.selectedDate);
    }
  
  }

}
