import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'

@Pipe({
  name: 'moment'
})
export class momentPipe implements PipeTransform {

  constructor(){}

  transform(date, format): any {
    return moment(date).format(format);
  }

}
 