import { LanguageService } from 'src/app/shared/services/language.service';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { UserService } from '../../services/user.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {
  
  @Input() isOpen: boolean = false;
  maySelect: boolean = false;

  public menuItems: Menu[];
  public url: any;
  public logo: string;

  constructor(
    private router: Router,
    private navServices: NavService,
    private userService: UserService,
    public languageService: LanguageService
    // public elRef: ElementRef
  ) {
    
    this.logo = environment.urlAdmin + this.userService?.login?.company?.logo || "../../assets/img/brand/logo-light.svg"
    this.navServices.getModules().subscribe(menuItems => {
      this.menuItems = menuItems;

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === (event.url)) {
              this.setNavActive(items);
            }
            if (!items.children) { return false; }
            items.children.filter(subItems => {
              if (subItems.path === event.url) {
                this.setNavActive(subItems);
              }
              if (!subItems.children) { return false; }
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url) {
                  this.setNavActive(subSubItems);
                }
              });
            });
          });
        }
      });
    });
  }

  ngOnChanges(){
    setTimeout(() => {
      this.maySelect = this.isOpen;
    }, 100);
  }

  //Active NavBar State
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      item.active = true;

      if (menuItem !== item) {
        menuItem.active = false;
        document.querySelector('.main-body').classList.remove('main-sidebar-show')       
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

 
  // Click Toggle menu
  toggleNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }

  ngOnInit(): void {
  }


}
