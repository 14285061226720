<!-- Main Footer-->
<div class="main-footer text-center">
    <ng-content></ng-content>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <span>Copyright © {{ today | date: 'y'}}
                    <a href="https://www.feedbakmx.com" target="_blank">FEEDBAK-IT S DE RL DE CV</a>.
                    All rights reserved.</span>
            </div>
        </div>
    </div>
</div>
<!--End Footer-->