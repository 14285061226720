import { Injectable } from '@angular/core';
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: 'root'
})

/**
 * Servicio para desencriptar datos
 */
export class CryptoSharkService {

  constructor() {}
  /**
   * Encriptar valor. Cifrado SHA
   * @param topSecret Valor a encriptar
   * @returns string
   */
  public encryptSha = (topSecret: string): string =>  CryptoJS.SHA3(topSecret).toString();

  /**
   * Encriptar valor con llave. Cifrado AES
   * @param topSecret Valor a encriptar
   * @param password Llave de encriptado
   * @returns string
   */
  public encryptAes = (topSecret: string, password = "7A3D3234235F4D65215A384366326738"): string =>  CryptoJS.AES.encrypt(topSecret, CryptoJS.enc.Hex.parse(password), {iv:CryptoJS.enc.Hex.parse(password)}).toString();

  /**
   * Desencriptar valor. Cifrado AES
   * @param topSecret Valor a desencriptar
   * @param password Llave de encriptación
   * @returns string
   */
  public decrypt = (topSecret: string, password = "7A3D3234235F4D65215A384366326738"): string =>  CryptoJS.AES.decrypt(topSecret, CryptoJS.enc.Hex.parse(password), {iv:CryptoJS.enc.Hex.parse(password)}).toString(CryptoJS.enc.Utf8);

  public decryptItems = (employee: any, password: string): any =>  {
    try {
      Object.keys(employee).map((element) => {
        if(typeof employee[element] == "boolean" || typeof employee[element] == "undefined" || !employee[element] || typeof employee[element] == "number" || element == 'id' || element == '_id') {
          return employee[element];
        } else {
          return employee[element] = this.decrypt(employee[element], password);
        }
      });
    } catch(error) {
      throw new Error('Ps Error')
    }
    return employee;
  }
}
