import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../services/user.service';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'imageMenu'
})
export class ImageMenuPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer, private userService: UserService){}

  transform(img: any, media:boolean = false): any {
    let domImg: string = '/assets/img/logo-light.svg';
    if (media && img) {
      domImg = img;
    }else if(img && this.userService.login.company.server){
      domImg = `${this.userService.login.company.server}/assets/${img}`;
    }

    return this.domSanitizer.bypassSecurityTrustResourceUrl(domImg);
  }

}
