import { NgModule } from '@angular/core';
import { DecryptPipe } from './decrypt.pipe';
import { ImageMenuPipe } from './imageCompany.pipe';
import { ImagesLoadPipe } from "./imagesLoad.pipe";
import { momentPipe } from './moment.pipe';
import { statusPipe } from './status.pipe';
import { translatePipe } from './translate.pipe';
@NgModule({
	declarations: [ImageMenuPipe,ImagesLoadPipe, DecryptPipe, statusPipe, translatePipe, momentPipe],
	imports: [],
	exports: [ImageMenuPipe,ImagesLoadPipe, DecryptPipe, statusPipe, translatePipe, momentPipe]
})
export class PipesModule {}
