// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlAdmin: 'https://admin.fdkapp.com/',
  //urlAdmin: 'https://admin.fdkapp.com/', //'https://mkdm1.fdkapp.com/',
    //urlAdmin: 'http://localhost:9000/',
  apiUrl: 'http://localhost:9000/',
  clientUrl: '',
  socketUrl: 'http://127.0.0.1:9001/',
  subdomain: '',
  url_monday: 'https://api.monday.com/v2',
  token_monday: 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjQ4NjQwMTY3LCJ1aWQiOjE0NDk4ODIxLCJpYWQiOiIyMDIwLTA2LTA5VDAxOjU4OjE1LjAwMFoiLCJwZXIiOiJtZTp3cml0ZSJ9.S3uFnk5FIim4KKacAv2V5BbFvakaEj51lZQBJd5w_ZA',
  columna_app_monday: 'Mi Kiosko',
  // url_mk_assets: "https://sjc1.vultrobjects.com/mikiosko/assets/",
  // url_storage_checador: "https://sjc1.vultrobjects.com/checador/",
};

 

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
