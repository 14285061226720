import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { GlobalAlertComponent } from "./alert/alert.component";
import { GlobalAlertWrapperComponent } from "./wrapper/wrapper.component";

@NgModule({
  declarations: [
    GlobalAlertComponent,
    GlobalAlertWrapperComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
  ],
  exports: [
    GlobalAlertComponent,
    GlobalAlertWrapperComponent
  ]
})
export class GlobalAlertsDialogModule { }
