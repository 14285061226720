import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { UserService } from "./user.service";

@Injectable({
    providedIn: 'root'
})

/**
 * Guard para verificar que el usuario tenga permiso de acceso y/o rol. El nombre del rol se encuentra en UserService
 * 
 * INSTRUCCIONES DE USO: 
 * 1) Utilizar en canActivate de cada modulo
 * 2) Proporcionar un objeto data: {role: ROL_ESPERADO}
 */

export class RoleGuard implements CanActivate {
    private expectedRole: string

    constructor(private userService: UserService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.userService.user.roleName === "ADMIN") {
            return true
        }

        this.expectedRole = route.data.role
        
        if (this.userService.user.permissions[this.expectedRole]?.access) {
            return true
        } else {
            let firstPermission:any = Object.entries(this.userService.user.permissions);
            firstPermission = firstPermission.filter(function ([key, value]) {
                return value["access"];
            });
            firstPermission = firstPermission[0][0];
            this.router.navigate([`/${firstPermission}`])
        }
    }

}